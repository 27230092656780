@mixin vertical-horizontal-align($position: relative) {
    position: $position;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

@mixin card-box-shadow() {
    box-shadow:
        0 3px 1px -2px rgba(0, 0, 0, 0.2),
        0 2px 2px 0 rgba(0, 0, 0, 0.14),
        0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

@mixin button-reset {
    background: transparent;
    border: 0;
}

@mixin rtl {
    ::ng-deep {
        html[dir='rtl'] {
            @content;
        }
    }
}

@mixin flexHideLt($size) {
    @media (max-width: $size) {
        display: none;
    }
}
@mixin flexHide($minSize, $maxSize) {
    @media screen and (min-width: $minSize) and (max-width: $maxSize) {
        display: none;
    }
}
@mixin flexHideGt($size) {
    @media (min-width: $size) {
        display: none;
    }
}

@mixin flexLt($flex-basis, $media-size: null) {
    @if $media-size {
        @media screen and (max-width: $media-size) {
            flex: 1 1 $flex-basis;
        }
    } @else {
        flex: 1 1 $flex-basis;
    }
}

@mixin flexGt($flex-basis, $media-size: null) {
    @if $media-size {
        @media screen and (min-width: $media-size) {
            flex: 1 1 $flex-basis;
        }
    } @else {
        flex: 1 1 $flex-basis;
    }
}

@mixin center-content {
    display: flex;
    justify-content: center;
    align-items: center;
}

@mixin ion-padding-all($padding: 0) {
    --padding-bottom: #{$padding};
    --padding-top: #{$padding};
    --padding-start: #{$padding};
    --padding-end: #{$padding};
}

@mixin ion-margin-all($padding: 0) {
    --margin-bottom: #{$padding};
    --margin-top: #{$padding};
    --margin-start: #{$padding};
    --margin-end: #{$padding};
}

@mixin ion-inner-padding-all($padding: 0) {
    --inner-padding-bottom: #{$padding};
    --inner-padding-top: #{$padding};
    --inner-padding-start: #{$padding};
    --inner-padding-end: #{$padding};
}

@mixin block-custom-css-hiding() {
    position: static !important;
    display: block !important;
    opacity: 1 !important;
    visibility: initial !important;
}

@mixin multi-line-text-truncate($lines: 2) {
    -webkit-box-orient: vertical;
    -webkit-line-clamp: $lines;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
}

@mixin compact-rich-text-content() {
    ::ng-deep {
        p,
        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        ol,
        ul {
            margin: 0 !important;
            padding: 0 !important;

            &:empty {
                display: none;
            }
        }

        br {
            display: none;
        }
    }
}

@mixin shimmer($color1: #f0f0f0, $color2: #e0e0e0) {
    background: linear-gradient(90deg, $color1 25%, $color2 50%, $color1 75%);
    background-size: 200% 100%;
    animation: shimmer 1.5s infinite linear;
    border-radius: 4px;

    @content;

    @keyframes shimmer {
        0% {
            background-position: 100% 0;
        }
        100% {
            background-position: -100% 0;
        }
    }
}
